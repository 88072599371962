<template>
  <div class="d-md-flex flex-row">
    <!-- begin::Aside -->
    <div class="flex-row-auto w-100 w-md-300px w-xl-350px">
      <div class="card card-custom">
        <div class="card-body pt-4">
          <!-- begin::Profile -->
          <div class="d-flex align-items-center">
            <div class="symbol symbol-50 mr-5 align-self-start align-self-xxl-center symbol-light-success">
              <div class="symbol-label font-size-h5 font-weight-bold">
                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" />
              </div>
            </div>
            <div>
              <span class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">Updates</span>
            </div>
          </div>
          <!-- end::Profile -->

          <!-- begin::Navigation -->
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded pt-5">
            <div class="navi-item mb-2">
              <router-link to="/admin/updates/types/P10241" active-class="active" class="navi-link py-4">
                <span class="navi-icon mr-4">
                  <span class="svg-icon">
                    <font-awesome-icon :icon="['fas', 'microchip']" class="h-20px w-20px" />
                  </span>
                </span>
                <span class="navi-text">
                  <span class="d-block font-size-lg">{{ $t('updates.types.P10241.title') }}</span>
                  <span class="text-muted">{{ $t('updates.types.P10241.description') }}</span>
                </span>
              </router-link>
            </div>
          </div>
          <!-- end::Navigation -->
        </div>
      </div>
    </div>
    <!-- end::Aside -->

    <!-- begin::Content -->
    <div class="flex-row-fluid mt-8 mt-md-0 ml-md-8">
      <router-view
        ref="router-view"
        :key="$route.path"
      />
    </div>
    <!-- end::Content -->
  </div>
</template>

<script>
export default {
  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Admin' }, { title: 'Updates' }]);
  },
};
</script>
